import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import React from 'react'
import LoadingButton from '../LoadingButton'

const WarningModal = ({
  open,
  handleClose,
  handleConfirmDelete,
  isDeleteLoading = false,
  name,
}) => {
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h5">Delete {name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this {name}?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleConfirmDelete}
          variant="contained"
          color="error"
          isLoading={isDeleteLoading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default WarningModal
