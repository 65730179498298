import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, IconButton, Paper, Stack } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import axios from 'axios'
import LoadingButton from '../../../component/LoadingButton'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import Quote from '@editorjs/quote'
import InlineCode from '@editorjs/inline-code'
import Marker from '@editorjs/marker'
import Delimiter from '@editorjs/delimiter'
import ImageTool from '@editorjs/image'
import Embed from '@editorjs/embed'
import Checklist from '@editorjs/checklist'
import Code from '@editorjs/code'
import { createBlog, getAllCategory } from '../../../api'
import CategoryModal from '../CatergoryModal'
import { toast } from 'react-toastify'

const extractMainHeading = (editorData) => {
  if (!editorData || !editorData.blocks) return ''
  for (const block of editorData.blocks) {
    if (block.type === 'header') {
      return block.data.text
    }
  }
  return ''
}

const CreateBlog = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [editorData, setEditorData] = useState()
  const [categoryData, setCategoryData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    fetchAllCategory()
  }, [])

  const fetchAllCategory = () => {
    getAllCategory()
      .then((res) => {
        if (res) {
          let temp = []
          res?.data?.data?.forEach((ele) => {
            temp.push({
              label: ele.title
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
              value: ele._id,
            })
          })
          setCategoryData(temp)
        }
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    if (ref.current && typeof ref.current.destroy === 'function') {
      ref.current.destroy()
    }

    const editor = new EditorJS({
      holder: 'createEditor',
      placeholder: 'Start writing your blog...',
      autofocus: false,
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        checklist: Checklist,
        list: List,
        header: Header,
        delimiter: Delimiter,
        // linkTool: LinkTool,
        inlineCode: InlineCode,
        // table: Table,
        marker: Marker,
        quote: Quote,
        embed: Embed,
        code: {
          class: Code,
          config: {
            languages: ['javascript', 'python', 'java', 'c++', 'c'],
          },
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              async uploadByFile(file) {
                const formData = new FormData()
                formData.append('image', file)
                const response = await axios.post(
                  `https://api.technithunder.com/api/imageUpload`,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: false,
                  },
                )
                if (response?.data) {
                  return response.data
                }
              },
            },
          },
        },
      },
      async onChange(api) {
        const savedData = await api.saver.save()
        setEditorData(savedData)
      },
    })

    ref.current = editor

    return () => {
      if (ref.current && typeof ref.current.destroy === 'function') {
        ref.current.destroy()
      }
    }
  }, [])

  const checkForHeaderAndImage = (data) => {
    if (!data) return false

    const blocks = data.blocks || []
    const hasHeader = blocks.some((block) => block?.type === 'header')
    const hasImage = blocks.some((block) => block?.type === 'image')

    return hasHeader && hasImage
  }

  const onPublish = () => {
    if (!checkForHeaderAndImage(editorData)) {
      alert('Please add a heading and an image before publishing.')
      return
    }
    setIsOpen(true)
  }

  const onSubmit = () => {
    setIsLoading(true)
    let blogData = {
      title: extractMainHeading(editorData),
      category: selectedCategory?.label,
      content: JSON.stringify(editorData),
    }
    createBlog(blogData)
      .then((res) => {
        if (res?.data) {
          setIsLoading(false)
          setIsOpen(false)
          navigate('/blogs')
          toast.success('Blog created successfully')
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message)
        setIsLoading(false)
      })
  }

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue)
  }

  return (
    <Paper sx={{ py: 2 }}>
      <Container>
        <Stack justifyContent="space-between" direction="row">
          <IconButton onClick={() => navigate('/blogs')}>
            <ArrowBackIcon />
          </IconButton>
          <LoadingButton
            onClick={onPublish}
            disabled={!editorData}
            variant="contained"
          >
            Publish
          </LoadingButton>
        </Stack>
        <Box sx={{ mt: 2 }}>
          <div id="createEditor" />
        </Box>
      </Container>
      <CategoryModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        options={categoryData}
        onChange={handleCategoryChange}
        value={selectedCategory}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Paper>
  )
}

export default CreateBlog
