import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Login from './pages/auth/login'
import Blogs from './pages/Blogs'
import CreateBlog from './pages/Blogs/create'
import EditBlog from './pages/Blogs/edit'
import ViewBlog from './pages/Blogs/view'
import CaseStudy from './pages/CaseStudy'
import AddCaseStudy from './pages/CaseStudy/create'
import Category from './pages/Category'
import ManageCategory from './pages/Category/Create'
import ClientReview from './pages/ClientReview'
import ManageClientReview from './pages/ClientReview/ManageClientReview'
import Register from './pages/auth/Register'
import NotFound from './component/NotFound'

function App() {
  const loginInfo = useSelector((state) => state.auth.adminInfo)
  const role = loginInfo?.role

  return (
    <Routes>
      <Route path="/" element={<Navigate to={'/login'} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/blogs" element={<Blogs />}>
        <Route path="create" element={<CreateBlog />} />
        <Route path="edit/:id" element={<EditBlog />} />
        <Route path="view/:id" element={<ViewBlog />} />
      </Route>
      {role === 'Admin' && (
        <Route path="/case-study" element={<CaseStudy />}>
          <Route path="create/:id?" element={<AddCaseStudy />} />
        </Route>
      )}
      <Route path="/category" element={<Category />}>
        <Route path="manage-category/:id?" element={<ManageCategory />} />
      </Route>
      {role === 'Admin' && (
        <Route path="/client-review" element={<ClientReview />}>
          <Route path="manage-review/:id?" element={<ManageClientReview />} />
        </Route>
      )}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default App
