import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  adminInfo: null,
  isAuthenticated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginInfo: (state, action) => {
      state.adminInfo = action.payload
      state.isAuthenticated = true
    },

    logout: (state) => {
      state.adminInfo = null
      state.isAuthenticated = false
    },
  },
})

export const { loginInfo, logout } = authSlice.actions

export default authSlice.reducer
