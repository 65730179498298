import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Stack,
  IconButton,
  Drawer,
  useMediaQuery,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { logout } from '../../pages/redux/slice/authSlice'
import LOGO from '../../assets/images/technithunder.svg'

// Styles for active and inactive NavLink
const activeStyle = {
  backgroundColor: '#1976d2',
  fontWeight: 'bold',
  textDecoration: 'none',
  color: '#ffffff',
  padding: '12px 20px',
  borderRadius: '10px',
}

const inActiveStyle = {
  textDecoration: 'none',
  color: '#000000',
  padding: '12px 20px',
}

const SidebarContent = ({ role }) => (
  <Box m={3}>
    <Stack spacing={2}>
      <NavLink
        to="/blogs"
        style={({ isActive }) => (isActive ? activeStyle : inActiveStyle)}
      >
        Blog
      </NavLink>
      {role === 'Admin' && (
        <NavLink
          to="/case-study"
          style={({ isActive }) => (isActive ? activeStyle : inActiveStyle)}
        >
          Case study
        </NavLink>
      )}
      <NavLink
        to="/category"
        style={({ isActive }) => (isActive ? activeStyle : inActiveStyle)}
      >
        Category
      </NavLink>
      {role === 'Admin' && (
        <NavLink
          to="/client-review"
          style={({ isActive }) => (isActive ? activeStyle : inActiveStyle)}
        >
          Client Review
        </NavLink>
      )}
    </Stack>
  </Box>
)

const Sidebar = ({ children }) => {
  const loginInfo = useSelector((state) => state.auth.adminInfo)
  const role = loginInfo?.role
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const manageLogout = () => {
    dispatch(logout())
    toast.success('Logout Successfully')
    navigate('/login')
  }

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {isSmallScreen ? (
        <>
          <Stack
            direction={'row'}
            position={'fixed'}
            top={16}
            left={16}
            zIndex={1000}
          >
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <img
              src={LOGO}
              alt="Technithunder Logo"
              style={{ width: isSmallScreen ? 140 : 180 }}
            />
          </Stack>

          <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
            <Box sx={{ width: isSmallScreen ? 200 : 250 }}>
              <Box
                sx={{
                  height: 70,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={LOGO}
                  alt="Technithunder Logo"
                  style={{ width: 180 }}
                />
              </Box>
              <Divider />
              <SidebarContent role={role} />
            </Box>
          </Drawer>
        </>
      ) : (
        <Box
          sx={{
            width: '15%',
            backgroundColor: '#ffffff',
            borderRight: '1px solid lightgray',
            position: 'fixed',
            height: '100%',
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              height: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={LOGO} alt="Technithunder Logo" style={{ width: 180 }} />
          </Box>
          <Divider />
          <SidebarContent manageLogout={manageLogout} role={role} />
        </Box>
      )}

      <Box
        sx={{
          marginLeft: isSmallScreen ? 0 : '15%',
          width: isSmallScreen ? '100%' : '85%',
          height: '100vh',
          overflowY: 'auto',
          backgroundColor: '#eeeeee',
        }}
      >
        <Box
          sx={{
            zIndex: 999,
            height: 70,
            backgroundColor: '#ffffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            position: 'fixed',
            width: isSmallScreen ? '100%' : '85%',
          }}
        >
          <Button onClick={manageLogout} sx={{ mr: 2 }} variant="outlined">
            Logout
          </Button>
        </Box>

        <Box mt={10} p={4}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Sidebar
