import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Sidebar from '../../component/Sidebar'
import ReviewList from './List'
import withAuth from '../../hooks/withauth.jsx'

const ClientReview = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <Sidebar>
      {location.pathname === '/client-review' ? (
        <Box>
          <Card sx={{ p: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Client Review</Typography>
              <Button
                onClick={() => navigate('/client-review/manage-review')}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Review
              </Button>
            </Stack>
          </Card>
          <Box mt={3}>
            <ReviewList />
          </Box>
        </Box>
      ) : (
        <Outlet />
      )}
    </Sidebar>
  )
}

export default withAuth(ClientReview)
