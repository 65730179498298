import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { adminLogin } from '../../../api'
import { loginInfo } from '../../redux/slice/authSlice'
import LoadingButton from '../../../component/LoadingButton'
import withAuth from '../../../hooks/withauth.jsx'
import { toast } from 'react-toastify'
import LOGO from '../../../assets/images/technithunder.svg'

const Login = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const schema = yup.object({
    email: yup
      .string()
      .email('Invalid email format')
      .matches(
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{3,4}$/,
        'enter a valid email',
      )
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters.'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const onSubmit = async (values) => {
    setIsLoading(true)
    const payload = {
      email: values.email,
      password: values.password,
    }
    adminLogin(payload)
      .then((res) => {
        if (res?.data) {
          dispatch(loginInfo(res?.data))
          navigate('/blogs')
          toast.success('Login Successfully')
          setIsLoading(false)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        toast.error(e?.response?.data?.message)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Box sx={{ height: '100vh' }}>
        <Stack
          alignItems="center"
          spacing={4}
          justifyContent="center"
          height={'100%'}
        >
          <img
            src={LOGO}
            alt="Technithunder Logo"
            style={{ width: 250, marginBottom: '20px' }}
          />
          <Typography variant="h4">Login</Typography>
          <TextField
            placeholder="Enter Email"
            variant="outlined"
            sx={{ width: '400px' }}
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />
          <TextField
            placeholder="Enter Password"
            variant="outlined"
            sx={{ width: '400px' }}
            type={showPassword ? 'text' : 'password'}
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            variant="contained"
            sx={{ width: 400 }}
            type="submit"
            isLoading={isLoading}
          >
            <Typography>Login</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </form>
  )
}

export default withAuth(Login)
