import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { getAllCategory } from '../../api/index'

const SelectCategory = ({ setSelectCategory, selectCategory }) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    fetchAllCategories()
  }, [])

  const fetchAllCategories = async () => {
    try {
      const res = await getAllCategory()
      if (res?.data.success) {
        setCategories(res?.data?.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChange = (e) => {
    setSelectCategory(e.target.value)
  }

  return (
    <FormControl sx={{ mt: 2, minWidth: 120 }} size="small">
      <InputLabel>Category</InputLabel>
      <Select value={selectCategory} label="Category" onChange={handleChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {categories.map((cat) => (
          <MenuItem key={cat._id} value={cat._id}>
            {cat.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectCategory
