import React from 'react'

const Header = ({ content }) => {
  const { text, level } = content.data
  const HeaderTag = `h${level}`

  const headerStyle = { margin: '40px 0 20px 0' }

  return (
    <HeaderTag style={headerStyle} dangerouslySetInnerHTML={{ __html: text }} />
  )
}

export default Header
