import React, { useEffect, useRef } from 'react'

const Paragraph = ({ content }) => {
  const paragraphRef = useRef(null)

  useEffect(() => {
    const paragraph = paragraphRef.current
    if (paragraph) {
      const links = paragraph.getElementsByTagName('a')
      for (let link of links) {
        link.style.color = '#10468E'
        link.style.textDecoration = 'underline'
      }
    }
  }, [content])

  return (
    <p
      ref={paragraphRef}
      style={{ margin: '20px 0 20px 0', fontSize: '18px' }}
      dangerouslySetInnerHTML={{ __html: content.data.text }}
    />
  )
}

export default Paragraph
