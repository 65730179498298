import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Pagination,
  Grid,
  CircularProgress,
  Card,
  Typography,
} from '@mui/material'
import BlogCard from './BlogList'
import { getAllBlogs, deleteBlog } from '../../../api'
import WarningModal from '../../../component/WarningModal'
import { toast } from 'react-toastify'
import SelectCategory from '../../../component/SelectCategory'

const BlogList = () => {
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectCategory, setSelectCategory] = useState(null)
  const itemsPerPage = 6

  useEffect(() => {
    fetchAllBlogs()
  }, [])

  const openDeleteDialog = (id) => {
    setDeleteId(id)
    setIsOpenDeleteModal(true)
  }

  const closeDeleteDialog = () => {
    setDeleteId(null)
    setIsDeleteLoading(false)
    setIsOpenDeleteModal(false)
  }

  const fetchAllBlogs = () => {
    setIsLoading(true)
    getAllBlogs()
      .then((res) => {
        if (res) {
          setIsLoading(false)
          setData(res?.data?.data.categories || [])
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const confirmDelete = () => {
    setIsDeleteLoading(true)
    if (deleteId) {
      deleteBlog(deleteId)
        .then((res) => {
          if (res) {
            fetchAllBlogs()
            setIsDeleteLoading(false)
            closeDeleteDialog()
            toast.success('Blog deleted successfully')
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message)
          closeDeleteDialog()
        })
    }
  }

  const filterData = useMemo(() => {
    return selectCategory
      ? data.filter((ele) => ele.categoryId === selectCategory)
      : data
  }, [selectCategory, data])

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedData = filterData.slice(startIndex, endIndex)

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  return (
    <Box>
      {isLoading ? (
        <Box display={'flex'} justifyContent={'center'} mt={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={2}>
          <SelectCategory
            setSelectCategory={setSelectCategory}
            selectCategory={selectCategory}
          />
          <Card sx={{ p: 2, mt: 2 }}>
            {filterData.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="200px"
              >
                <Typography variant="h5" color="textSecondary">
                  No Data Found
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={5}>
                {paginatedData.map((category) =>
                  category?.blogs?.map((item) => (
                    <Grid item xs={12} sm={6} md={3} key={item._id}>
                      <BlogCard
                        category={category?.name}
                        item={item}
                        content={JSON.parse(item.content)}
                        onDelete={openDeleteDialog}
                      />
                    </Grid>
                  )),
                )}
              </Grid>
            )}
          </Card>
        </Box>
      )}

      {filterData.length > 0 && (
        <Pagination
          sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}
          count={Math.ceil(filterData.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      )}
      {isOpenDeleteModal && (
        <WarningModal
          name={'Blog'}
          handleClose={closeDeleteDialog}
          isDeleteLoading={isDeleteLoading}
          open={isOpenDeleteModal}
          handleConfirmDelete={confirmDelete}
        />
      )}
    </Box>
  )
}

export default BlogList
