import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import Sidebar from '../../component/Sidebar'
import AddIcon from '@mui/icons-material/Add'
import BlogList from './list'
import withAuth from '../../hooks/withauth.jsx'

const Blogs = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Sidebar>
      {location.pathname === '/blogs' ? (
        <Box>
          <Card sx={{ p: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Blogs</Typography>
              <Button
                onClick={() => navigate('/blogs/create')}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Blog
              </Button>
            </Stack>
          </Card>

          <BlogList />
        </Box>
      ) : (
        <Outlet />
      )}
    </Sidebar>
  )
}

export default withAuth(Blogs)
