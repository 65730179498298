import React, { useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
  Pagination,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const CaseStudyList = ({ data, onDelete }) => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedData = data.slice(startIndex, endIndex)

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  return (
    <>
      <Grid container>
        {data?.map((ele, index) => {
          return (
            <Grid item marginTop={2} md={3} key={index}>
              <Card sx={{ minHeight: 200, margin: 2, height: '100%' }}>
                {ele.introduction.image && (
                  <CardMedia>
                    <LazyLoadImage
                      src={ele?.introduction?.image}
                      alt="Blog image"
                      style={{ width: '100%', height: 200, objectFit: 'cover' }}
                    />
                  </CardMedia>
                )}

                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/case-study/${ele._id}`)}
                  >
                    {ele?.introduction?.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    mt={2}
                    color="textSecondary"
                    sx={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {ele?.introduction?.descriptions[0]}
                  </Typography>

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ justifyContent: 'flex-end', marginTop: 2 }}
                  >
                    <IconButton
                      onClick={() => navigate(`/case-study/create/${ele._id}`)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDelete(ele._id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>

      {paginatedData.length > 0 && (
        <Pagination
          sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}
          count={Math.ceil(data.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      )}
    </>
  )
}

export default CaseStudyList
